import PropTypes from "prop-types";

import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import ModalWindowContent from "@clearpoint/old-theme/ModalWindow/ModalWindow.Content";
import ModalWindowFooter from "@clearpoint/old-theme/ModalWindow/ModalWindow.Footer";
import ModalWindowHeader from "@clearpoint/old-theme/ModalWindow/ModalWindow.Header";
import ModalWindowTabContainer from "@clearpoint/old-theme/ModalWindow/ModalWindow.TabContainer";

import { useTranslate } from "@clearpoint/translate";

import PlaceChartTab from "./PlaceChartTab";

let propTypes = {
	incomingChart: PropTypes.object,
};

let PlaceChartModalContent = ({ incomingChart }) => {
	let translate = useTranslate();
	let { title } = useFormValue() ?? {};

	let badgeText = incomingChart ? translate("manage.chart.duplicateChart") : translate("edit.placeChart");

	return (
		<>
			<ModalWindowHeader badgeIcon="chart" badgeText={badgeText} title={title || badgeText} />
			<ModalWindowContent>
				<ModalWindowTabContainer>
					<PlaceChartTab title={badgeText} />
				</ModalWindowTabContainer>
			</ModalWindowContent>
			<ModalWindowFooter />
		</>
	);
};

PlaceChartModalContent.propTypes = propTypes;
export default PlaceChartModalContent;
