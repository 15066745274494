import Column from "@clearpoint/old-theme/Column";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import ScorecardSelect from "@clearpoint/old-theme/Form/Select/Select.Scorecard";
import SelectShadowMeasure from "@clearpoint/old-theme/Form/Select/Select.ShadowMeasure";
import Row from "@clearpoint/old-theme/Row";

import { useTranslate } from "@clearpoint/translate";

import ChartConfiguration from "./ChartConfiguration";
import ChartTypeRadio from "./ChartTypeRadio";
import ElementTypeSelect from "./ElementTypeSelect";

let PlaceChartTab = () => {
	let translate = useTranslate();
	let { object, scorecardId } = useFormValue("chart") ?? {};

	return (
		<>
			<Row>
				<Column tiny={12}>
					<ChartTypeRadio />
				</Column>
				<Column medium={12} large={6}>
					<ScorecardSelect name="chart.scorecardId" label={translate("scorecard")} />
				</Column>
				<Column medium={12} large={6}>
					<ElementTypeSelect />
				</Column>
				<Column tiny={12}>
					<SelectShadowMeasure
						name="chart.measureId"
						label={translate(object)}
						object={object}
						scorecardId={scorecardId}
						required
					/>
				</Column>
			</Row>
			<ChartConfiguration />
		</>
	);
};

export default PlaceChartTab;
