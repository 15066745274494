import { useCallback } from "react";
import PropTypes from "prop-types";

import { useUserType } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

import useCheckFeature from "../useCheckFeature";
import mergeCustomFieldList from "./mergeCustomFieldList";
import { useOldSession } from "@clearpoint/old-session/index";

let propTypes = {
	scorecardId: PropTypes.number,
};
let useScorecardEditFieldList = ({ scorecardId }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let { session } = useOldSession();
	let checkFeature = useCheckFeature();
	let tagFlag = checkFeature("tags");
	let userType = useUserType();
	let adminFlag = userType === "Administrator";
	let currentScorecardFlag = scorecardId === session?.scorecardId;

	let customFieldList = get({ object: "customField", queryString: "?object=scorecard" });

	return useCallback(() => {
		let filteredCustomFieldList = customFieldList
			?.filter((x) => !x.updateMode)
			.filter((x) => !x.scorecardId || x.scorecardId.length === 0 || x.scorecardId.includes(scorecardId));
		let fieldList = [
			{
				value: "tags",
				label: translate("global.tags"),
				sortOrder: -996,
				group: translate("scorecard"),
			},
			{
				value: "parentId",
				label: translate("options.parentScorecard"),
				sortOrder: -990,
				group: translate("scorecard"),
			},
			{
				value: "archived",
				label: translate("goals.archived"),
				sortOrder: -989,
				group: translate("scorecard"),
				hideInModal: true,
			},
			{
				value: "locked",
				label: translate("options.locked"),
				sortOrder: -988,
				group: translate("scorecard"),
				hideInModal: true,
			},
			// {
			//  	value: "ownerId",
			//  	label: translate("global.owner"),
			//  	sortOrder: -998,
			//  	group: translate("scorecard"),
			//  },
			//  {
			//  	value: "collaborators",
			//  	label: translate("collaborators"),
			//  	sortOrder: -997,
			//  	group: translate("scorecard"),
			//  },
			{
				value: "grantAdminAccess",
				label: translate("edit.element.grantAccess"),
				sortOrder: -987,
				group: translate("scorecard"),
				addFlag: true,
			},
		];
		if (!tagFlag) fieldList = fieldList.filter((x) => x.value !== "tags");
		if (!adminFlag || currentScorecardFlag) fieldList = fieldList.filter((x) => x.value !== "archived");
		return mergeCustomFieldList(fieldList, filteredCustomFieldList, translate);
	}, [adminFlag, tagFlag, scorecardId, currentScorecardFlag, translate, customFieldList]);
};
useScorecardEditFieldList.propTypes = propTypes;
export default useScorecardEditFieldList;
