import { useCallback } from "react";
import { v1 as uuid } from "uuid";

import { useFormContext } from "@clearpoint/old-theme/Form/Form";

import { createUniqueIdFromPod } from "@clearpoint/utils";

import { useDragAndDropContext } from "../DragAndDropWrapper/DragAndDropWrapper";
import createNewPodForLayoutPanel from "../MenuPanel/createNewPodForLayoutPanel";

let usePlaceChartSubmit = ({ areaIndex, close, incomingChart }) => {
	let { setFormValue } = useFormContext() ?? {};
	let { setDragAndDropLookup } = useDragAndDropContext();

	let insertNewChartPodIntoArea = useCallback(
		(chart, areaIndex) => {
			let newChartPod = createNewPodForLayoutPanel({
				chart: { ...chart, _id: uuid() },
				fieldId: "kpi",
				type: "kpi",
				updateMode: true,
				name: chart.title,
			});
			setFormValue(`layout[${areaIndex}].pods`, (podList) => {
				if (podList) {
					return [newChartPod, ...podList];
				}
			});
			setDragAndDropLookup((dragAndDropPodIdLookup) => ({
				...dragAndDropPodIdLookup,
				"layout[0]": [createUniqueIdFromPod(newChartPod), ...dragAndDropPodIdLookup["layout[0]"]],
			}));
		},
		[setFormValue, setDragAndDropLookup]
	);

	return useCallback(
		({ chart }) => {
			if (incomingChart) {
				insertNewChartPodIntoArea(chart, areaIndex);
			} else {
				insertNewChartPodIntoArea(chart, 0);
			}
			close();
		},
		[incomingChart, close, insertNewChartPodIntoArea, areaIndex]
	);
};

export default usePlaceChartSubmit;
