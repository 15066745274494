import { useMemo } from "react";
import PropTypes from "prop-types";

import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import ModalWindow, { useWindowContext } from "@clearpoint/old-theme/ModalWindow/ModalWindow";
import { theme } from "@clearpoint/old-theme/Theme";

import { useEvaluationEnabledLookup } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";

import { CHART } from "./chartTypes";
import PlaceChartModalContent from "./PlaceChartModalContent";
import usePlaceChartSubmit from "./usePlaceChartSubmit";

let propTypes = {
	areaIndex: PropTypes.number,
	chart: PropTypes.object,
	close: PropTypes.func.isRequired,
	modalWindowVisible: PropTypes.bool.isRequired,
	podIndex: PropTypes.number,
};

let PlaceChartModal = ({ areaIndex, chart: incomingChart, close, modalWindowVisible, podIndex }) => {
	let { get } = useOldQueryStore();
	let { object, objectId } = useFormValue() ?? {};
	let { elementScorecardId } = useWindowContext();
	let evaluationEnabledFlag = useEvaluationEnabledLookup()[object];

	let defaultValueMeasureId;
	if (!incomingChart) {
		if (object === "measure") {
			defaultValueMeasureId = objectId;
		} else if (evaluationEnabledFlag) {
			defaultValueMeasureId = get({ object, objectId })?.shadowMeasureId;
		} else {
			defaultValueMeasureId = get({ object: "measure", scorecardId: elementScorecardId })?.[0]?.objectId;
		}
	}

	let defaultValue = useMemo(
		() =>
			incomingChart
				? { chart: incomingChart ?? {} }
				: {
						chart: {
							chartType: CHART,
							color1: theme.info,
							color2: "#a7e8e9",
							measureId: defaultValueMeasureId,
							object: evaluationEnabledFlag ? object : "measure",
							scorecardId: elementScorecardId,
						},
				  },
		[defaultValueMeasureId, elementScorecardId, evaluationEnabledFlag, incomingChart, object]
	);

	let onSubmit = usePlaceChartSubmit({ areaIndex, incomingChart, close, podIndex });

	return (
		<ModalWindow
			close={close}
			color="lazur"
			defaultValue={defaultValue}
			elementScorecardId={elementScorecardId}
			modalWindowVisible={modalWindowVisible}
			submitUnchangedFlag
			onSubmit={onSubmit}
			size="medium"
		>
			<PlaceChartModalContent incomingChart={incomingChart} />
		</ModalWindow>
	);
};

PlaceChartModal.propTypes = propTypes;
export default PlaceChartModal;
