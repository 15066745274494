import Badge from "@clearpoint/old-theme/Badge/Badge";
import Block from "@clearpoint/old-theme/Block";
import ChecklistCheck from "@clearpoint/old-theme/Checklist/Checklist.Check";
import DeletedText from "@clearpoint/old-theme/Text/Text.Deleted";
import DropdownItemMilestoneCopy from "./Dropdown.Item.MilestoneCopy";
import DropdownItemEdit from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item.Edit";
import DropdownItemTrash from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item.Trash";
import EditMilestoneModal from "./EditMilestoneModal";
import FormDragAndDropListItem from "@clearpoint/old-theme/Form/DragAndDropList/DragAndDropList.Item";
import KebabButton from "@clearpoint/old-theme/Dropdown/Kebab/KebabButton";
import PercentCompletePie from "@clearpoint/old-theme/PercentCompletePie";
import StatusIcon from "@clearpoint/old-theme/StatusIcon";
import TagList from "@clearpoint/old-theme/Tag/Tag.List";
import TrashButton from "@clearpoint/old-theme/Button/Button.Trash";
import UndeleteButton from "@clearpoint/old-theme/Button/Button.Undelete";
import { Fragment, useCallback } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";
import { emptyObject } from "@clearpoint/utils";
import { useCheckAccess, useFormatDate, useMeasure } from "@clearpoint/hooks";

let propTypes = {
	milestone: PropTypes.shape({
		active: PropTypes.bool,
		completed: PropTypes.bool,
		completedDate: PropTypes.string,
		deleteDate: PropTypes.string,
		endDate: PropTypes.string,
		lastEdited: PropTypes.string,
		name: PropTypes.string,
		objectId: PropTypes.number,
		scorecardId: PropTypes.number,
		startDate: PropTypes.string,
		statusId: PropTypes.number,
		tags: PropTypes.arrayOf(
			PropTypes.oneOfType([
				PropTypes.number,
				PropTypes.shape({
					color: PropTypes.string,
					name: PropTypes.string,
					tagId: PropTypes.number,
				}),
			])
		),
		visible: PropTypes.bool,
	}).isRequired,
};

let MilestoneListItem = ({ milestone }) => {
	let {
		access,
		active,
		completed,
		completedDate,
		deleteDate,
		endDate,
		lastEdited,
		name,
		objectId,
		percentComplete,
		scorecardId,
		startDate,
		statusId,
		tags,
		visible,
	} = milestone;
	let translate = useTranslate();
	let formatDate = useFormatDate();
	let checkAccess = useCheckAccess();
	let editFlag = access && checkAccess({ access, action: "edit", scorecardId });
	let DateText = useCallback(
		() => (
			<Block marginLeft={theme.space}>
				{completed
					? `${translate("detailLayouts.completed")} ${formatDate(completedDate, "short")}`
					: `${formatDate(startDate, "short")} - ${formatDate(endDate, "short")}`}
			</Block>
		),
		[completed, completedDate, endDate, formatDate, startDate, translate]
	);
	let { treeLevel } = milestone;
	let Check = editFlag ? ChecklistCheck : Fragment;
	let { width: containerWidth } = useMeasure({ query: ".milestone-list-item-container" }) || emptyObject;
	let largeScreenFlag = containerWidth > 800;
	return (
		<FormDragAndDropListItem
			ellipsisFlag
			border={`1px solid ${theme.lightGray}`}
			boxShadow={theme.modalListItemBoxShadow}
			className="milestone-list-item-container"
			color="desaturatedNormal"
			disabled={!editFlag}
			lastEdited={lastEdited}
			visible={visible}
			left={
				<Block display="flex" alignItems="center">
					<Check objectId={editFlag ? objectId : undefined}>
						<StatusIcon statusId={statusId} />
					</Check>
					<Block
						ellipsisFlag
						marginLeft={`${treeLevel * 20}px`}
						paddingRight={theme.smallSpace}
						fontWeight={theme.boldish}
					>
						{name}
					</Block>
					{largeScreenFlag && (
						<Block display="flex" alignItems="center">
							<TagList tags={tags} />
							{!active && <Badge color="danger">{translate("global.deleted")}</Badge>}
						</Block>
					)}
				</Block>
			}
			right={
				active ? (
					<>
						{largeScreenFlag && (
							<Block display="flex" alignItems="center">
								<PercentCompletePie percentComplete={completed ? 100 : percentComplete || 0} />
								<DateText />
							</Block>
						)}

						<TrashButton object="milestone" objectId={objectId} name={name} disabled={!editFlag} />
						<KebabButton>
							<DropdownItemEdit
								ModalWindow={EditMilestoneModal}
								object="milestone"
								objectId={objectId}
								size="medium"
							/>
							{editFlag && (
								<>
									<DropdownItemMilestoneCopy objectId={objectId} />
									<DropdownItemTrash object="milestone" objectId={objectId} name={name} />
								</>
							)}
						</KebabButton>
					</>
				) : (
					<>
						<Block paddingBottom={theme.tinySpace} marginRight={theme.space}>
							<DeletedText deleteDate={deleteDate} />
						</Block>
						<DateText />
						{editFlag && (
							<UndeleteButton
								object="milestone"
								objectId={objectId}
								title={translate("button.undeleteElement")}
								body={translate("button.undeleteElementConfirm")}
							/>
						)}
					</>
				)
			}
		/>
	);
};

MilestoneListItem.propTypes = propTypes;

export default MilestoneListItem;
