import { Draggable } from "react-smooth-dnd";
import ListItem from "../ListItem/ListItem";
import PropTypes from "prop-types";

let propTypes = {
	children: PropTypes.node,
	"data-testid": PropTypes.string,
	disabled: PropTypes.bool,
	handle: PropTypes.string,
	lastEdited: PropTypes.string,
	left: PropTypes.node,
	right: PropTypes.node,
	visible: PropTypes.bool,
};

let DragAndDropItem = ({ "data-testid": dataTestId, handle, disabled, ...props }) => {
	return (
		<Draggable>
			<ListItem data-testid={dataTestId || "react-draggable"} draggableFlag={!disabled} {...props} />
		</Draggable>
	);
};

DragAndDropItem.propTypes = propTypes;

export default DragAndDropItem;
