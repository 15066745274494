import { useEffect } from "react";
import isArray from "lodash/isArray";
import PropTypes from "prop-types";

import { useFormContext } from "@clearpoint/old-theme/Form/Form";

import { useFormatDate } from "@clearpoint/hooks";
import { useTranslate } from "@clearpoint/translate";

let propTypes = {
	link: PropTypes.shape({
		completed: PropTypes.bool,
		completedDate: PropTypes.any,
		endDate: PropTypes.string,
		object: PropTypes.string,
		objectId: PropTypes.number,
		startDate: PropTypes.string,
	}),
};

let Date = ({ link }) => {
	let translate = useTranslate();
	let formatDate = useFormatDate();
	let { endDate, object, objectId, startDate } = link;
	let { setFormValue, formValue } = useFormContext();

	let formLink = formValue?.links?.find((link) => link?.objectId === objectId);
	let ganttData = formValue?.ganttData?.find((x) => x.objectId === objectId);

	useEffect(() => {
		let setFormLink = (value) => {
			let index = isArray(formValue?.links)
				? formValue?.links?.findIndex?.((link) => link?.objectId === objectId)
				: -1;
			if (index !== -1) {
				setFormValue(`links.${index}.completedDate`, value);
			}
		};

		if (ganttData?.completed === true && ganttData?.completedDate !== "1900-01-01") {
			setFormLink(ganttData?.completedDate);
		} else {
			setFormLink("1900-01-01");
		}
	}, [ganttData?.completed, ganttData?.completedDate, formValue.links, setFormValue, objectId]);

	let visible = ["milestone", "initiative", "actionItem", "risk"].includes(object);

	return visible ? (
		<small>
			{formLink?.completed && formLink?.completedDate !== "1900-01-01"
				? `${translate("components.completed")} ${formatDate(formLink?.completedDate, "short")}`
				: `${formatDate(startDate, "short")} - ${formatDate(endDate, "short")}`}
		</small>
	) : null;
};

Date.propTypes = propTypes;
export default Date;

// Responsiveness we're probably not going to wind up using:

// let showFlag = props.showFlag,
// let smallScreenFlag = props.smallScreenFlag
// let visible = ["milestone", "initiative", "actionItem", "risk"].includes(object) && (showFlag || !smallScreenFlag);
