import { createContext, useContext, useEffect } from "react";
import PropTypes from "prop-types";

import { useId } from "@clearpoint/hooks";

import { colorPalettes } from "../colorPalettes";
import Form from "../Form/Form";
import StyledReactModal from "./StyledReactModal";
import useHandleSubmit from "./useHandleSubmit";
import useHandleThirdPartyPlugins from "./useHandleThirdPartyPlugins";
import useObjectState from "./useObjectState";
import useWindowContextValue from "./useWindowContextValue";
import useWindowState from "./useWindowState";
import WindowContainer from "./WindowContainer";
import WindowTabProvider from "./WindowTabProvider";

let ModalWindowContext = createContext();

let propTypes = {
	beforeSubmit: PropTypes.func,
	emptySubmitWarning: PropTypes.string,
	children: PropTypes.node,
	className: PropTypes.string,
	close: PropTypes.func.isRequired,
	color: PropTypes.oneOf(Object.keys(colorPalettes)),
	copyFlag: PropTypes.bool,
	"data-testid": PropTypes.string,
	defaultValue: PropTypes.object,
	modalWindowVisible: PropTypes.bool,
	newObjectFlag: PropTypes.bool,
	noFormFlag: PropTypes.bool,
	object: PropTypes.string,
	objectIdList: PropTypes.arrayOf(PropTypes.number),
	onSubmit: PropTypes.func,
	parent: PropTypes.string,
	parentId: PropTypes.number,
	periodId: PropTypes.number,
	queryString: PropTypes.string,
	queryStringOnSubmit: PropTypes.string,
	scorecardId: PropTypes.number,
	setObjectId: PropTypes.func,
	size: PropTypes.oneOf(["small", "medium", "large"]),
	skipClearFlag: PropTypes.bool,
	skipCloseFlag: PropTypes.bool,
	style: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	submitUnchangedFlag: PropTypes.bool,
	updateFlag: PropTypes.bool,
};

let defaultProps = {
	color: "normal",
	skipCloseFlag: false,
	size: "large",
};

const TRANSITION_DURATION = 500;
let INTERCOM_SELECTOR = "#intercom-container";
let getIntercom = () => document.querySelector(INTERCOM_SELECTOR);

let ModalWindow = ({
	beforeSubmit,
	emptySubmitWarning,
	children,
	className,
	close,
	color,
	copyFlag,
	"data-testid": dataTestId,
	defaultValue,
	modalWindowVisible,
	newObjectFlag,
	noFormFlag,
	object,
	objectIdList,
	onSubmit,
	parent,
	parentId,
	periodId,
	queryString,
	queryStringOnSubmit,
	scorecardId,
	size,
	skipClearFlag,
	skipCloseFlag,
	style,
	submitUnchangedFlag,
	updateFlag,
	...props
}) => {
	let id = useId();
	let loadingFlag;
	let noObjectIdPropFlag;
	let objectId;
	let setObjectId;
	({ loadingFlag, newObjectFlag, noObjectIdPropFlag, objectId, setObjectId } = useObjectState({
		props,
		defaultValue,
		object,
		scorecardId,
		queryString,
		newObjectFlag,
	}));
	let { helpFlag, maximizeFlag, toggleHelpFlag, toggleSize } = useWindowState();
	let handleClose = useHandleThirdPartyPlugins({ close, modalWindowVisible, TRANSITION_DURATION });
	// useHandleSubmit
	let handleSubmit = useHandleSubmit({ close, emptySubmitWarning, onSubmit, skipCloseFlag });
	// useWindowContextValue
	let windowContextValue = useWindowContextValue({
		handleClose,
		color,
		helpFlag,
		id,
		loadingFlag,
		maximizeFlag,
		noFormFlag,
		object,
		objectId,
		setObjectId,
		toggleHelpFlag,
		toggleSize,
		props,
	});
	useEffect(() => {
		if (!modalWindowVisible) {
			let intercom = getIntercom();
			if (intercom) intercom.style.removeProperty("display");
		}
		return () => {
			let intercom = getIntercom();
			if (intercom) intercom.style.removeProperty("display");
		};
	}, [modalWindowVisible]);
	return (
		<ModalWindowContext.Provider value={windowContextValue}>
			<StyledReactModal isOpen={modalWindowVisible} onRequestClose={close} TRANSITION_DURATION={TRANSITION_DURATION}>
				<WindowContainer
					data-testid={dataTestId}
					size={size}
					maximizeFlag={maximizeFlag}
					className={className}
					style={style}
				>
					{noFormFlag ? (
						<WindowTabProvider noFormFlag>{children}</WindowTabProvider>
					) : (
						<Form
							beforeSubmit={beforeSubmit}
							copyFlag={copyFlag}
							defaultValue={defaultValue}
							newObjectFlag={newObjectFlag}
							noObjectIdPropFlag={noObjectIdPropFlag}
							object={object}
							objectId={objectId}
							objectIdList={objectIdList}
							onSubmit={handleSubmit}
							queryString={queryString}
							queryStringOnSubmit={queryStringOnSubmit}
							scorecardId={scorecardId}
							skipClearFlag={skipClearFlag}
							submitUnchangedFlag={submitUnchangedFlag}
							updateFlag={updateFlag}
							parent={parent}
							parentId={parentId}
							periodId={periodId}
						>
							<WindowTabProvider>{children}</WindowTabProvider>
						</Form>
					)}
				</WindowContainer>
			</StyledReactModal>
		</ModalWindowContext.Provider>
	);
};

ModalWindow.propTypes = propTypes;
ModalWindow.defaultProps = defaultProps;

export default ModalWindow;
export const useWindowContext = () => useContext(ModalWindowContext);
export { TRANSITION_DURATION };
