import Block from "@clearpoint/old-theme/Block";
import CellStyleWrapper from "../CellStyleWrapper";
import CellWrapper from "../CellWrapper";
import FormFieldDisplaySeriesData from "@clearpoint/old-theme/Form/Field/Display/SeriesData";
import FormFieldSeriesData from "@clearpoint/old-theme/Form/Field/SeriesData";
import StatusIcon from "@clearpoint/old-theme/StatusIcon";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { emptyObject } from "@clearpoint/utils";

let propTypes = {
	cell: PropTypes.shape({
		hideRepeated: PropTypes.bool,
		displayPeriodId: PropTypes.number,
		periodId: PropTypes.number,
		statusDisplay: PropTypes.string,
	}),
	editCellFlag: PropTypes.bool,
	parentName: PropTypes.string,
};
let SeriesDataCell = (props) => {
	let { editCellFlag, parentName, cell } = props;
	let { hideRepeated: hideRepeatedFlag, periodId, statusDisplay } = cell;
	let seriesData = useFormValue(`${parentName}.seriesData`);
	let periodIndex = useMemo(() => {
		let index = seriesData && seriesData.findIndex((x) => x.periodId === periodId);
		if (index === -1) {
			return seriesData.findIndex((x) => x.periodId - periodId > 0) - 1;
		} else {
			return index;
		}
	}, [periodId, seriesData]);
	let periodSeriesData = seriesData && seriesData[periodIndex];
	let { displayPeriodId, statusId } = periodSeriesData || emptyObject;
	let hideDataFlag = hideRepeatedFlag && displayPeriodId !== periodId;

	return useMemo(
		() => (
			<CellStyleWrapper {...props} {...cell} statusId={statusId}>
				<CellWrapper hideDataFlag={hideDataFlag} {...props}>
					{editCellFlag ? (
						<FormFieldSeriesData name={`${parentName}.seriesData[${periodIndex}].value`} marginBottom="0px" />
					) : (
						!hideDataFlag && (
							<Block display="flex" justifyContent="space-between">
								{statusDisplay === "icon" && statusId ? <StatusIcon statusId={statusId} /> : <div />}
								<FormFieldDisplaySeriesData
									name={`${parentName}.seriesData[${periodIndex}].value`}
									parentName={parentName}
								/>
							</Block>
						)
					)}
				</CellWrapper>
			</CellStyleWrapper>
		),
		[cell, editCellFlag, hideDataFlag, parentName, periodIndex, props, statusDisplay, statusId]
	);
};
SeriesDataCell.propTypes = propTypes;

export default SeriesDataCell;
