import { useCallback, useEffect } from "react";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { removeUndefined } from "@clearpoint/utils";
import { toast } from "@clearpoint/services/toastService/index";

import useStateObject from "./useStateObject";

let useHomepage = ({ layoutId, page, object, objectId, scorecardId }) => {
	let translate = useTranslate();
	let [{ homepageFlag }, setState] = useStateObject({
		homepageFlag: false,
	});
	let { set, get, clear } = useOldQueryStore();
	let profile = get({ object: "profile" });
	let profileUserId = profile?.userId;

	useEffect(() => {
		if (profile) {
			let { home } = profile;
			if (home.object || home.homepage) {
				let objectMatchFlag = home.object && home.object === object;
				let objectIdMatchFlag = home.objectId && home.objectId === objectId;
				let layoutIdMatchFlag = home.layoutId && home.layoutId === layoutId;
				let idMatchFlag = objectIdMatchFlag || layoutIdMatchFlag;
				let pageMatchFlag = home.homepage === page || home.homepage === `${object}?objectId=${objectId}`;
				let defaultHomepageFlag = home.default;
				let newHomepageFlag =
					!defaultHomepageFlag && (home.homepage ? pageMatchFlag : objectMatchFlag && idMatchFlag);
				setState({ homepageFlag: newHomepageFlag });
			}
		}
	}, [homepageFlag, layoutId, object, objectId, page, profile, setState]);

	let toggleHomepage = useCallback(() => {
		setState({
			homepageFlag: (x) => !x,
		});
		set({
			object: "favorite",
			data: removeUndefined({
				favorite: true,
				homepage: !homepageFlag,
				layoutId,
				page,
				object,
				objectId,
				scorecardId,
			}),
		}).then(() => {
			if (homepageFlag) {
				toast.success(translate("myProfile.alertHomeReset"));
			} else {
				toast.success(translate("toaster.messages.favorites.homepageSet"));
			}
			clear({ object: "myFavorite" });
			clear({ object: "favorite" });
			clear({ object: "profile" });
			if (profileUserId) {
				clear({ object: "user", objectId: profileUserId });
			}
			if (!layoutId) clear({ object, objectId });
		});
	}, [clear, homepageFlag, layoutId, object, objectId, page, profileUserId, scorecardId, set, setState, translate]);

	return { homepageFlag, toggleHomepage };
};
export default useHomepage;
