import Block from "@clearpoint/old-theme/Block";
import Dropdown from "@clearpoint/old-theme/Dropdown/Dropdown";
import DropdownDivider from "@clearpoint/old-theme/Dropdown/Dropdown.Divider";
import DropdownMenu from "@clearpoint/old-theme/Dropdown/Dropdown.Menu";
import DropdownToggle from "@clearpoint/old-theme/Dropdown/Dropdown.Toggle";
import Icon from "@clearpoint/old-theme/Icon/Icon";
import { theme } from "@clearpoint/old-theme/Theme";

import { useQueryParameters } from "@clearpoint/hooks";
import { useTranslate } from "@clearpoint/translate";

import MenuItem from "./MenuItem";
import useFilteredMenu from "./useFilteredMenu";

let Menu = () => {
	let translate = useTranslate();
	let { object } = useQueryParameters();
	let filteredMenu = useFilteredMenu();
	return filteredMenu ? (
		<Block
			backgroundColor="white"
			borderBottom={`1px solid ${theme.lightGray}`}
			padding={`${theme.space} 8px`}
			position="sticky"
			top="0"
			zIndex="9999999"
		>
			{filteredMenu?.map((menuObject) => {
				return (
					<Dropdown key={menuObject.object}>
						<DropdownToggle color={menuObject.object === object ? "primary" : "neutral"}>
							<Icon name={menuObject.object} />
							&nbsp;&nbsp;{translate(`${menuObject.object}Menu`)}
						</DropdownToggle>
						<DropdownMenu>
							{menuObject.layouts?.length > 0 &&
								menuObject?.layouts?.map((layout) => (
									<MenuItem key={layout.layoutId || layout.objectId} menuItem={layout} />
								))}
							{menuObject.layouts?.length > 0 && menuObject.objects?.length > 0 && <DropdownDivider />}
							{menuObject.objects?.length > 0 &&
								menuObject?.objects
									?.filter((x, i, self) => self.findIndex((y) => x.name === y.name) === i)
									?.map((object) => (
										<MenuItem
											key={object.objectId || object.layoutId}
											menuItem={object}
											scorecardObjectFlag={menuObject.object === "scorecard"}
										/>
									))}
						</DropdownMenu>
					</Dropdown>
				);
			})}
		</Block>
	) : null;
};

export default Menu;
